import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What makes SparkSocialX different from other social media management services?",
      answer:
      "SparkSocialX stands out by offering tailored strategies for small businesses, combining affordability with premium service. We focus on amplifying your brand's unique voice, providing personalized content creation, and delivering measurable results that directly impact your business growth.",
      },
      {
      question: "How long does it take to see results from your services?",
      answer:
      "While every business is unique, most clients start seeing improved engagement within the first month. Significant growth in followers and website traffic typically occurs within 3-6 months. We provide regular reports to track progress and continuously optimize our strategies for the best results.",
      },
      {
      question: "Can I change my package or cancel the service if needed?",
      answer:
      "Absolutely! We understand that business needs can change. You can upgrade or downgrade your package at any time. Our services are month-to-month, so you can cancel with 30 days' notice. We're confident in our service and don't believe in locking you into long-term contracts.",
      },
      {
      question: "Do I need to provide my own content or will you create everything?",
      answer:
      "Our team creates custom content for your brand, but we welcome your input and ideas. We'll work closely with you to understand your brand voice and preferences. You're always welcome to provide content or suggest topics, but it's not required. Our goal is to make the process as hands-off as you'd like it to be.",
      },
      {
      question: "How do you measure the success of your social media strategies?",
      answer:
      "We use a combination of metrics to measure success, including engagement rates, follower growth, website traffic, and conversions. We also track industry-specific KPIs relevant to your business goals. Our regular reports provide transparent insights into these metrics, allowing you to see the tangible impact of our strategies.",
      },
      {
      question: "Can SparkSocialX handle multiple social media platforms?",
      answer:
      "Yes, we specialize in managing multiple social media platforms. Our services cover all major platforms including Facebook, Instagram, Twitter, LinkedIn, and more. We tailor content for each platform to maximize engagement and reach, ensuring a cohesive brand message across all your social media channels.",
      },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
