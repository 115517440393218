import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
// import { Link } from "./../util/router";
import { TypeAnimation } from 'react-type-animation';

function HeroSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
          />
          {/* <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button> */}

<div>
  <TypeAnimation
    preRenderFirstString={true}
    sequence={[
      500,
      'Amplify your', // initially rendered starting point
      1000,
      "Amplify your reach",
      1500,
      "Amplify your impact",
      1500,
      "Amplify your message",
      1500,
      "Amplify your potential",
      1500,
      "Amplify your brand recognition",
      1500,
      "Amplify your visibility",
      1500,
      "Amplify your growth" ,
      1500,
      "Amplify your influence",
      1500,
      "Amplify your brand story",
      1500,
      "Amplify your online presence",
      1500,
      "Amplify your market share",
      1500,
      "Amplify your customer base",
      1500,
      "Amplify your digital footprint",
      1500,
      "Amplify your business goals",
      1500,
      "Amplify your industry leadership",
      1500,
      "Amplify your social media impact",
      1500,
    ]}
    speed={50}
    style={{ fontSize: '2.5em', display: 'inline-block', fontWeight: 'bold' }}
    repeat={Infinity}
  />
</div>
        </Box>

      </Container>
    </Section>
  );
}

export default HeroSection;
