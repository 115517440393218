import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title="Igniting Digital Success for Small Businesses"
        subtitle="
At SparkSocialX, we believe every small business has a unique spark waiting to shine in the digital world. Founded in 2023 by a team of passionate digital marketers and small business advocates, we set out on a mission to level the playing field in the online landscape.
We understand the challenges small business owners face. You're juggling multiple roles, trying to keep up with ever-changing digital trends, all while staying true to your passion. That's where we come in.

Our Spark:
We're not just another digital marketing agency. We're your partners in amplification. Our team combines expertise in content creation, social media management, and SEO with a deep understanding of small business needs. We take your brand's unique voice and amplify it across the digital landscape, turning your spark into a brilliant online presence.

Our Approach:
We believe in tailored strategies, not one-size-fits-all solutions. When you work with SparkSocialX, you get a dedicated team that takes the time to understand your business, your goals, and your audience. We then craft a customized digital strategy that aligns with your vision and budget.

Our Promise:
We're committed to delivering results that matter to your business. Whether it's increased engagement, higher website traffic, or more leads, we measure our success by your growth. With SparkSocialX, you can focus on what you do best – running your business – while we take care of making you shine online.
Join us in our mission to amplify small business voices in the digital world. Let's turn your spark into a digital wildfire together!
Ready to ignite your online presence? Contact us today for a free consultation."
      />
      <StatsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      />
    </>
  );
}

export default AboutPage;
